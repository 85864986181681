import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Markdown } from '../components/Markdown'
import { Container } from '../components/Container'
import { ReadMore, ReadMoreProps } from '../components/ReadMore'

interface Props {
  data: {
    pageData: {
      title: string
      body: string
    }
    links: {
      nodes: Array<ReadMoreProps>
    }
  }
}

function PracticeArea(props: Props) {
  const { pageData } = props.data

  const links = props.data.links.nodes.map(link => ({
    title: link.title,
    to: `/verksamhetsomraden/${link.slug}/`,
  }))

  return (
    <Layout spaceTop={true}>
      <SEO title={pageData.title} />

      <Container>
        <h1 className="font-serif text-3xl text-gray-700 pb-3">{pageData.title}</h1>
        <hr />

        <div className="max-w-3xl pt-6 pb-12">
          <Markdown source={pageData.body} className="c-markdown" />
        </div>

        <ReadMore links={links} />
      </Container>
    </Layout>
  )
}

export default PracticeArea

export const query = graphql`
  query($slug: String!) {
    pageData: datoCmsPracticeArea(slug: { eq: $slug }) {
      title
      body
    }

    links: allDatoCmsPracticeArea(filter: { slug: { ne: $slug } }) {
      nodes {
        slug
        title
      }
    }
  }
`
