import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

export interface ReadMoreProps {
  links: Array<{
    title: string
    to: string
  }>
}

function ReadMore(props: ReadMoreProps) {
  return (
    <div>
      <p className="font-bold border-b-2 text-gray-700 inline-block">Läs mer</p>
      <ul className="pt-3">
        {props.links.map((link, idx) => (
          <li
            key={idx}
            className={classNames({
              'mt-1': idx !== 0,
            })}
          >
            <Link to={link.to} className="text-blue-600 hover:underline">
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export { ReadMore }
